import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code';
import vceApi from '../vceApi';
import { Button, Checkbox, Dropdown, DropdownItem, TextInput } from 'suomifi-ui-components';
import { useTranslation } from 'react-i18next';
import { SimplifiedPresentationPayload } from '../api/vce-api/vce-api';


type Props = {
  credentialType: string,
  claimsCSV: string,
  client_id_scheme?: "did"|"redirect_uri"|"x509_san_dns",
  pathType?: "vc+sd-jwt"|"jwt_vc_json",
  callback: (data: /*{ [key: string]: unknown }*/SimplifiedPresentationPayload)=>void,
  title?: string,
  showWalletAddress?: boolean
  showOptions?: boolean
//  useTypeFilter?: boolean
}

function RequestCredential({ callback, credentialType, claimsCSV, client_id_scheme, pathType, title, showWalletAddress, showOptions/*, useCredentialTypeFilter*/ }: Props) {
  // qrcode related stuff
  const [requestState, setRequestState] = useState<string>("");
  const [counter, setCounter] = useState<number>(-1);
  const [redirectUri, setRedirectUri] = useState<string | undefined>(undefined);
  const [showRenew, setShowRenew] = useState<boolean>(false);
  const { t } = useTranslation();

  const [walletAddress, setWalletAddress] = useState<string|undefined>(localStorage.getItem("walletAddress")||"");
  const [errorText, setErrorText] = useState<string|undefined>("");

  const [toggleSettings, setToggleSettings] = useState<boolean>(false)
  const [togglePresentation, setTogglePresentation] = useState<boolean>(false)
  const [presentationData, setPresentationData] = useState<string>("")
  

  //const [ multiPath, setMultiPath ] = useState<boolean>(false);
  //const [ sdJwtOnly, setSdJwtOnly ] = useState<boolean>(false);
  const [ clientIdScheme, setClientIdScheme ] = useState<"did"|"redirect_uri"|"x509_san_dns"|"">(client_id_scheme||"redirect_uri");  // default value redirect_uri, also in server
  const [ formatType, setFormatType ] = useState<"vc+sd-jwt"|"jwt_vc_json"|"">(pathType||"");  
  const [ useCredentialType, setUseCredentialType ] = useState<boolean>(true);
//  const [ useTypeFilter, setUseTypeFilter ] = useState<boolean>(useCredentialTypeFilter || true);


  const onSendRequestToWalletClick = async () => {
    if (!walletAddress || !redirectUri)
      return;
    // Trim wallet address and try to post open id request to the address
    const walletAddressTrimmed = walletAddress.trim();
    if (walletAddressTrimmed.length > 0) {
      let holderUrl = walletAddress;
      localStorage.setItem("walletAddress", holderUrl);
      if (walletAddressTrimmed.startsWith("did:web:")) { 
        holderUrl = (walletAddressTrimmed.indexOf("localhost")>=0 ? "http://" : "https://") + decodeURIComponent(walletAddressTrimmed.substring(8).replaceAll(":", "/"))
        if (!holderUrl.endsWith("/authorize")) {
          holderUrl = holderUrl + "/authorize";
        }
      }
      else {
        holderUrl = walletAddressTrimmed;
      }
      try {
        // TODO const client_id_metadata = {} and append info to fetch
        const getResult = await fetch(holderUrl + "?" + redirectUri.replace("openid4vp://?", ""));
        console.log("get result", JSON.stringify(getResult));
      }
      catch(err: any) {
        console.log(err);
        setErrorText(err.messge)
      }
    }
  }

  const verifierName = "verifier";

  useEffect(() => {
    // only once
    debugger;
  }, []);

  // useEffect(() => {
  //   if (showRenew) {
  //     setCounter(-1);
  //   }
    
  // }, [showRenew]);

  // TODO
  // const { isPending, error, data } = useQuery({
  //   queryKey: ['repoData'],
  //   queryFn: () =>
  //     fetch('https://api.github.com/repos/TanStack/query').then((res) =>
  //       res.json(),
  //     ),
  // })

  useEffect(() => {
    const newState = window.crypto.randomUUID().replace(/-/g,"");

    // Create credential presentation request
    debugger;
    vceApi.verifiers.createPresentationRequestSimple(verifierName, credentialType, { 
      claimsCSV: claimsCSV, state: newState, /*clientId: `did:web:test.minisuomi.fi:api:verifiers:${verifierName}`*/
      //credentialType: useTypeFilter ? undefined, 
      filterType: useCredentialType,
      pathType: formatType||undefined, 
      client_id_scheme: clientIdScheme || undefined,
      
    }).then(x => {

      debugger;
      //x.data.presentationDefinition.input_descriptors[0].constraints.fields.filter(x => x.path.find(""))

      setPresentationData(JSON.stringify(x.data.presentationDefinition, null, 2));
      console.log(`redirectUri: ${x.data.redirectUri}`); // todo pitäisiköhän ohjata suomifi tunnistautumiseen? vai mikä tämä oli?
      //x.data.redirectUri = "https://"
      if (clientIdScheme !== x.data.client_id_scheme) {
        debugger;
        console.log("warning, client id shceme not same as before this xhr call?");
        return;    
        //setClientIdScheme(x.data.client_id_scheme);
      }
      //else {
        //x.data.client_id_scheme = x.data.client_id_scheme || undefined
        vceApi.verifiers.initiateTransaction(verifierName, x.data).then(x => {
        
          console.log(x.data.responseUri);

          setRequestState(newState);

          // Update url for qr code and start counter
          setRedirectUri(x.data.responseUri);
          setCounter(0);
        }).catch(x => console.log(x));
      //}         
    }).catch(x => console.log(x));
  }, [useCredentialType, claimsCSV, credentialType, formatType, clientIdScheme]);

  useEffect(() => {
    console.log(counter, requestState, showRenew);
    if (counter === -1 && !requestState) {
      // Init state
      const newState = window.crypto.randomUUID().replace(/-/g,"");
      setRequestState(newState);
    } else if (counter >= 0) {
      if (counter >= 10) { 
        console.log("Counter >= 10");
        setRequestState("");
        setRedirectUri("");
        setShowRenew(true);
      }
      else {
        // Polling function tries to poll about 30 seconds, credential presentation is valid about 3 minutes
        vceApi.verifiers.poll(verifierName, requestState, { credentials: "omit"}).then(x => {
          const data = x.data;
          console.log(data);  
          if (data !== null) {
            callback(data as SimplifiedPresentationPayload);
          }
          else {
            console.log("not verified yet");
            setCounter(counter+1);
          }
        }).catch((e: Error) => {
          console.log(e);
          if (e.message === "state not found") {
            // state not found, do not continue polling
          }
          else {
            console.log("unkonwn polling error? trying again...");
            setCounter(counter+1);
          }
          
        });

      }
    }
  }, [showRenew, counter, callback, requestState])

  return (<div style={{ display: "flex", alignItems: "center", flexDirection: "column"}}>
    { showOptions && 
      <div style={{marginBottom: "10px" }}>
        <p style={{color: toggleSettings ? "#003479" : "#ccc", cursor: "pointer"}} onClick={() => {setToggleSettings(!toggleSettings)}}>{toggleSettings ? 'hide compatibility settings' : 'show compatibility settings'}</p>
        { toggleSettings && 
          <>
          <Dropdown key="format_dropdown"
            labelText="Format"
            value={formatType}
            onChange={(newValue) => { setRedirectUri(""); setFormatType(newValue as "vc+sd-jwt"|"jwt_vc_json"|"") }}
          >
            <DropdownItem key="any" value="">*</DropdownItem>
            <DropdownItem key="vc+sd-jwt" value="vc+sd-jwt">sd-jwt</DropdownItem>
            <DropdownItem key="jwt_vc_json" value="jwt_vc_json">jwt</DropdownItem>  
          </Dropdown>

          <Dropdown key="client_id_scheme_dropdown"
            labelText="Client id scheme"
            value={clientIdScheme}
            onChange={(newValue) => { setRedirectUri(""); setClientIdScheme(newValue as "redirect_uri"|"did"|"x509_san_dns"|"") }}
          >
            <DropdownItem key="empty" value=""> </DropdownItem>
            <DropdownItem key="did" value="did">did</DropdownItem>
            <DropdownItem key="redirect_uri" value="redirect_uri">redirect_uri</DropdownItem>  
            <DropdownItem key="x509_san_dns" value="x509_san_dns">x509_san_dns</DropdownItem>  
          </Dropdown>


          <Checkbox checked={useCredentialType} onClick={(newState) => { setUseCredentialType(newState.checkboxState); }} defaultChecked={true} >{t("requestCredential.useCredentialType")}</Checkbox>
          </>
        }
      </div>
    }
    {redirectUri &&
      <div style={{ height: "auto", margin: "0 0", maxWidth: 192, width: "100%" }} title={`${credentialType}${formatType ? ` (${formatType})` : ''}: ${claimsCSV}`}>
        <a href={redirectUri}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={redirectUri}
            viewBox={`0 0 256 256`}
          />
        </a>
        <p style={{textAlign: "center", margin: 0}}>{title||credentialType}</p>
      </div>
      }
      { showRenew && <>
        <Button onClick={() => { setCounter(-1); setShowRenew(false) }}>{t("renew")}</Button>
        </>
      }

      { showWalletAddress && <div style={{display:"flex", flexDirection:"row", alignItems: "end"}}>
        <br/>
          <TextInput readOnly={false} className='longer-input' name='walletAddress' labelText={t("eudtrcView.walletAddress")} value={walletAddress} onChange={(e) => setWalletAddress(e as string)}></TextInput>
          <Button style={{marginBottom: "7px"}} disabled={!walletAddress} onClick={() => onSendRequestToWalletClick() }>Send request to wallet</Button>
          { errorText }
        </div>
      }

      { showOptions && 
      
      <div style={{marginBottom: "10px"}}>
        <p style={{color: togglePresentation ? "#003479" : "#ccc", cursor: "pointer"}} onClick={() => {setTogglePresentation(!togglePresentation)}}>{togglePresentation ? 'hide presentation' : 'show presentation'}</p>
        { togglePresentation && 
          <pre style={{ fontSize: "12px" }}>{presentationData}</pre>
        }
      </div>
      }
  
        {/* </> */}
      {/* } */}

    </div>
  )
}

export default RequestCredential
