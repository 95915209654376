import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        fallbackLng: "en",
        resources: {
            en: {
                translation: {
                    appName: "Mini-Bank",  
                    credentialNames: {
                        lpid: "Legal Person Identification Data (LPID)",
                        eucc: "Trade register extract (eucc)",
                        eucc2: "EU Company Certificate (EUCC)",
                        xbrlje: "Financial statement information (xbrlje)",
                        agent3: "Ultimate beneficial owners (agent3)",
                        etrc: "Tax residence certificate (etrc)",
                    },
                    credentialView: {
                        certificateTitle: "Tax residence certificate",
                        issuanceDate: "Issuance date",
                        issuer: "Issuer",
                        legalName: "Legal name", 
                        notation: "Notation",
                        registeredAddress: "Registered address",
                        visualize: "visualize",
                    },
                    eudtrcView: {
                        connect: "Connect to wallet",
                        requestPresentation: "Show presentation requests",
                        description: "Here you can submit your electronic tax residence certificate to the bank",
                        pollCertificate: "Preparing to load tax residence certificate...",
                        success: "Tax residence certificate request sent to the wallet",
                        title: "Tax residence certificate request",
                        walletAddress: "Wallet address (domain or web-did)",
                    }, 
                    ewcUrl: "https://eudiwalletconsortium.org/",
                    footer: {
                        accessibilityStatement: "accessibility statement",
                        contactInformation: "contact information",
                        dataProtection: "data protection"
                    }, 
                    homeView: {
                        etrcButton: "Submit a tax residence certificate", 
                        kycButton: "Open a bank account for a business",
                        pageDescription: "This is a demo site for testing <link1>Real time economy</link1> and <link2>EWC LSP</link2> organisation wallets.",
                        pageInfo: "Log in to demonstrate presenting certificates from a wallet to a bank.",
                    },
                    kycView: {
                        description: "In order to open a bank account, the bank needs the following certificates:",
                        fetchCredentials: "Submit wallet address",
                        showQrCodes: "Show requests as QR-codes",
                        info1: "Enter a wallet address from which Mini-Bank will fetch the certificates",
                        info2: "",
                        pollCertificate: "Preparing to load certificates..",
                        title: "Open a bank account for a business",
                    },
                    kycViewOld: {
                        description: "In order to open a bank account, the bank needs the following certificates:",
                        fetchCredentials: "Submit wallet address",
                        showQrCodes: "Show requests as QR-codes",
                        info1: "Click button to show requests.",
                        info2: "",
                        pollCertificate: "Preparing to load certificates..",
                        title: "Open a bank account",
                    },
                    login: "Log in",                   
                    loginView: {
                        email: "e-mail",
                        password: "password",
                        title: "Identification",
                        failed: "Login failed",
                    },
                    logout: "Log out",
                    logoutView: {
                        title: "You have been logged out",
                        redirect: "Continue to frontpage",
                    },
                    requestCredential: {
                        useCredentialType: "Use credential type/vct"
                    },
                    route: {
                        home: "Frontpage",
                        eudtrc: "Tax Residence Certificate",
                        kyc: "KYC"
                    },
                    ydUrl: "https://www.yrityksendigitalous.fi/en/",
                    welcome: "Welcome to Mini-Bank",
                }
            },
            fi: {
                translation: {
                    appName: "Mini-Pankki",
                    credentialNames: {
                        eucc: "Kaupparekisteriote (eucc)",
                        xbrlje: "Tilinpäätöstiedot (xbrlje)",
                        agent3: "Edunsaajaluettelo (agent3)",
                        etrc: "Verotuksellinen kotipaikkatodistus (etrc)",
                    },
                    credentialView: {
                        certificateTitle: "Kotipaikkatodistus",
                        issuanceDate: "Myöntöpäivä",
                        issuer: "Myöntäjä",
                        legalName: "Juridinen nimi", 
                        notation: "Notaatio",
                        registeredAddress: "Rekisteröity osoite",
                        visualize: "visualisoi",
                    },
                    eudtrcView: {
                        connect: "Yhdistä lompakkoon",
                        requestPresentation: "Näytä todistuspyynnöt",
                        description: "Tästä voit toimittaa verotuksellisen kotipaikkatodistuksesi pankkiin.",
                        pollCertificate: "Valmistellaan kotipaikkatodistuksen lataamista...",
                        success: "Pyyntö lähetetty lompakkoon",
                        title: "Kotipaikkatodistuksen lähettäminen",
                        walletAddress: "Lompakon osoite (www-sivu tai web-did)",
                    },
                    footer: {
                        accessibilityStatement: "saatavuusseloste",
                        contactInformation: "yhteystiedot",
                        dataProtection: "tietosuoja"
                    },
                    homeView: {
                        etrcButton: "Lähetä kotipaikkatodistus", 
                        kycButton: "Avaa pankkitili",
                        pageDescription: 'Tämä on demosivusto, joka on luotu <link1>Yrityksen digitalous</link1> ja <link2>EWC LSP</link2> kokeiluja varten.',
                        pageInfo: "Kirjaudu sisään demotaksesi todistusten lähettämistä lompakosta pankkiin.",
                    },
                    kycView: {
                        description: "Pankkitilin avaamista varten pankki tarvitsee seuraavat todistukset:",
                        fetchCredentials: "Lähetä lompakon osoite",
                        showQrCodes: "Näytä pyynnöt QR-koodeina",
                        info1: "Anna yrityslompakon osoite, josta Mini-Pankki hakee todistukset.",
                        info2: "",
                        pollCertificate: "Valmistautuu lataamaan todistuksia...",
                        title: "Avaa pankkitili",
                    },
                    login: "Kirjaudu sisään",
                    loginView: {
                        email: "sähköposti",
                        password: "salasana",
                        title: "Tunnistautuminen",
                        failed: "Kirjautuminen epäonnistui",
                    },
                    logout: "Kirjaudu ulos",
                    logoutView: {
                        title: "Olet uloskirjautunut",
                        redirect: "Siirry etusivulle",
                    },
                    
                    route: {
                        home: "Etusivu",
                        eudtrc: "Kotipaikkatodistus",
                        kyc: "KYC"
                    },
                    
                    ydUrl: "https://www.yrityksendigitalous.fi/",
                    welcome: "Tervetuloa Mini-Pankkiin",
                }
            },
            sv: {
                translation: {
                    appName: "Mini-Bank",
                    credentialView: {
                        certificateTitle: "Hemvistcertifikat",
                        issuanceDate: "Utfärdaredatum",
                        issuer: "Utfärdare",
                        legalName: "Juridiska namn",
                        notation: "Notation",
                        registeredAddress: "Registerad adress",
                        visualisera: "visualisera",
                    },
                    eudtrcView: {
                        connect: "Anslut till plånbok",
                        description: "Banken kräver hemvistcertifikat. Anslut till plånboken för att begära certifikatet",
                        pollCertificate: "Förbereder dig för att ladda ner hemvistbeviset...",// "Förbereder för att ladda upp hemvistbeviset...",
                        title: "Ansökan om uppehållstillstånd",
                        success: "Begäran om uppehållsintyg skickas till plånboken",
                        walletAddress: "Plånboksadress (www-sida eller webb-gjort)",
                    },
                    footer: {
                        accessibilityStatement: "tillgänglighetsförklaring",
                        contactInformation: "kontaktinformation",
                        dataProtection: "dataskydd"
                    },
                    homeView: {
                        etrcButton: "Gå till intyg om hemvist", 
                        kycButton: "Skapa ett bankkonto",
                        pageDescription: "Detta är en demowebbplats för att testa experiment med <1>Realtidsekonomi</1>.",
                        pageInfo: "Logga in på demo för att begära och skicka bevis på hemvist med hjälp av plånböcker.",
                    },
                    kycView: {
                        beskrivning: "För att skapa ett bankkonto behöver banken följande certifikat:",
                        fetchCredentials: "Hämta referenser från din företagsplånbok",
                        info1: "Du kan hämta de ovan nämnda certifikaten från din företagsplånbok genom att ange adressen till företagsplånboken.",
                        info2: "Efter detta, klicka på knappen för att hämta certifikaten.",
                        pollCertificate: "Förbereder att ladda upp certifikat...",
                        title: "KYC - Känna din kund",
                    },
                    login: "Logga in",
                    loginView: {
                        email: "e-post",
                        password: "lösenord",
                        title: "Identifiering",
                        failed: "Inloggningen misslyckades",
                    },
                    logout: "Logga ut",
                    logoutView: {
                        title: "Du är utloggad",
                        redirect: "Fortsätt till startsidan",
                    },
                    
                    route: {
                        home: "Hemsida",
                        eudtrc: "Hemvistcertifikat",
                        kyc: "KYC"
                    },       
                    ydUrl: "https://www.yrityksendigitalous.fi/sv/",
                    welcome: "Välkommen till Mini-Bank",
                }
            }
        }
    })